// GLOBAL SETTINGS
//////////////////////////////////////////////

$break: large; // Breakpoint – switch to Desktop version

// NAVIGATION
//////////////////////////////////////////////

$navBg: $light; // Navigation Background
$navColor: $light; // Navigation Link Color

@mixin navButton {
	// Default Link Styles
	padding: rem(14px) rem($baseGap);
	text-decoration: none;
	color: $navColor;
	font-size: rem(17px);
	font-weight: 600;
	line-height: rem(30px);
	position: relative;

		&:before{		
			content: '';
			position: absolute;
			background: $light;
			width: rem(10px);
			left: 0;
			visibility: hidden;
			opacity: 0;
			height: 100%;
			top: 0;

			.javascript &{
				transition: .6s ease;
			}
		}

	@include breakpoint($break){

		padding: 0;

		&:before {
			display: none;
		}

		&:after{		
			content: '';
			position: absolute;
			background: $light;
			width: 100%;
			left: 0;
			visibility: hidden;
			opacity: 0;
			height: 1px;
			bottom: rem(-10px);
			.javascript &{
				transition: .6s ease;
			}
		}

		.scrolled & {
			color: $secondary;

			&:after{
				background: $secondary;
			}
		}
	}
	
	@include breakpoint(huge){
		font-size: rem(18px);
	}
}
%buttonHover {
	// Link Hover
	@include breakpoint($break, max){	
	 padding: rem(14px) rem($baseGap) rem(14px) rem(26px);
		&:before {
			opacity: 1;
			visibility: visible;
			left: 0;
		}
	}

	@include breakpoint($break){		
		&:after{
			opacity: 1;
			visibility: visible;
			bottom: rem(3px);
		}
	}
}
%buttonActive {
	// Link Active

	@include breakpoint($break, max){	
	 padding: rem(14px) rem($baseGap) rem(14px) rem(26px);
		&:before {
			opacity: 1;
			visibility: visible;
			left: 0;
		}
	}

	@include breakpoint($break){		
		&:after{
			opacity: 1;
			visibility: visible;
			bottom: rem(3px);
		}	
	}
}
%prevIcon {
	// Icon to previus Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);
	transform: rotate(180deg);

	// @include breakpoint($break) {
	// 	transform:rotate(90deg);
	// }
}
%nextIcon {
	// Icon to next Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);

	@include breakpoint($break) {
		transform:rotate(90deg);
	}
}
$closeIcon: map-get($iconMap, times); // Icon to close Submenu

// Quickbar
//////////////////////////////////////////////

$barHeight: 50px; // height (in px)
$barPos: top; // position (top, bottom)
$barHide: true; // if bar hides on scroll (true, false)

$barBg: $dark; // background-color
$barColor: $light; // children colors – texts and burgerbutton

//////////////////////////////////////////////
// NAVIGATION FUNCTIONALITY
//////////////////////////////////////////////

@import "../modules/ruffnav";

// Navigation Styles
////
@include pageNavi {
	background: $gradientTop;
	background: linear-gradient(0deg, rgba($gradientBottom,1) 0%, rgba($gradientTop,1) 100%);
	z-index: 1001;

	nav {
		@include ul {
			&.navi {
				li {
					border-bottom: 1px solid rgba($light, 0.1);
					&:last-child {
						margin-bottom: transparent;
					}
					@include navButtons {
						@include navButton;
					}
					@include hasSub {
						@include sub {
							background: $navBg;

							&:before {
								padding: 1em rem($baseGap);
								background-color: darken($navBg, 6%);
							}
						}
					}
					@include navBack {
					}
				}
			}
		}
	}
}


// Navigation Desktop Styles
////
@include breakpoint($break) {
	@include desktopVersionFunctionality;
	

	#navigation {
		height: rem(146px);
		background: transparent;

		.scrolled & {
			box-shadow: 0 0 rem(8px) rem(1px) rgba($secondary, 0.1);
		}

		> .row {
			height: 100%;
		}

		.branding-box {
			height: rem(90px);
			background-color: $light;
			position: absolute;
			display: flex;
			align-items: center;
			width: rem(240px);
			z-index: 1;

				a {
					padding: 0 rem(40px) 0 0;
				}

			@include breakpoint(giant) {				
				width: auto;
				height: 100%;
				position: relative;

				a {
					padding: 0 rem($baseGap) 0 0;
				}
			}
			
			@include breakpoint(full) {
				padding-right: 0;

				a {
					padding: 0;
				}
			}

			&:before {
				content: '';
				background-color: $light;
				width: rem(6000px);
				height: 100%;
				display: block;
				position: absolute;
				left: rem(-6000px);
				top: 0;
			}
			&:after {
				content: '';
				background-color: $light;
				width: 0;
				height: 100%;
				display: block;
				position: absolute;
				left: rem(-$baseGap);
				top: 0;
				z-index: -1;

				.cmsBackend & {
					display: none;
				}

				.javascript & {
					transition: all 0.5s ease;
				}

				.scrolled & {
					width: rem(3000px);	
					height: rem(146px);	

					@include breakpoint(giant) {
						height: 100%;	
					}	
				}
			}
		}
		nav {
			ul {
				&.navi {
					position: relative;
					z-index: 2;
					justify-content: justify-content;

					@include breakpoint(giant) {
						justify-content: flex-end;
					}
					li {
						border-bottom: transparent;

						@include breakpoint(giant) {
							margin-left: rem(14px);
						}
						@include breakpoint(huge) {
							margin-left: rem(12px);
						}
						@include breakpoint(full) {
							margin-left: rem(18px);
						}
						&:first-child {
							margin-left: 0;
						}
						&.hasSub {
							.sub {
							}
						}
						&.login,
						&.kontakt,
						&.datenschutzerklaerung,
						&.anfahrt,
						&.impressum{
							display: none;
						}
						&.navBack {
						}
					}
				}
			}
		}
	}
}

#navigation {
	.cmsBackend & {
		position: absolute;
	}

	.navi {
		@include breakpoint($break, max) {
			padding-bottom: rem(60px);
		}
	}

	.branding-box,
	.info {
		@include breakpoint($break, max) {
			display: none;
		}
	}

	.nav-col {
		@include breakpoint($break, max) {
			padding: 0;
		}
	}
}

.info {
	color: $light;
	font-weight: 600;
	display: flex;
	justify-content: flex-end;
	padding-top: rem(20px);
	margin-bottom: rem(20px);
	position: relative;

	@include breakpoint(giant) {
		padding-top: rem(35px);
		margin-bottom: 0;
	}

	.javascript & {
		transition: color 0.3s ease;
	}

	.scrolled & {
		color: $secondary;	

		.cmsBackend & {
			color: $light;
		}

		a {
			color: $secondary;

			.cmsBackend & {
				color: $light;
			}	
		}
	}

	a {
		color: $light;

		&:hover,
		&:focus,
		&:active {
			text-decoration: none;
		}
	}

	ul {
		display: flex;
		align-items: center;
		margin-bottom: rem(15px);
		position: relative;
		z-index: 2;

		li {
			margin-right: rem(35px);

			&:last-child {
				margin-right: 0;
				margin-left: rem(12px);
			}
		}
	}

	.btn {
		font-size: rem(16px);
		padding: 0 rem(20px) 0 rem(52px);	  
		height: rem(39px);
		line-height: rem(39px);
		color: $secondary;

		&:hover,
		&:active,
		&:focus {
			color: $light;
		} 

		.scrolled & {
			background-color: $secondary;
			color: $light;

			&:hover,
			&:active,
			&:focus {
				background-color: rgba($secondary, 0.2);
				color: $secondary;
			}
		}

		*[class^="icon"] {
			font-size: rem(25px);
			width: rem(41px);
			height: rem(41px);
		}
	}
}

// Quickbar Styles
////
#quickbar {
	background-color: $primary;
	color: $light;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: rem($baseGap);
	z-index: 1000;
	font-weight: 600;

	a {
		color: $light;
	}

	@include breakpoint($break) {
		display: none;
	}
}

//////////////////////////////////////////////
// naviAdd
//////////////////////////////////////////////

.naviAddBox {
	@include breakpoint(small) {
		display: flex;
	}
}
.naviAdd {
	&:first-child {
		@include breakpoint(small) {
			margin-right: rem($baseGap);
		}

		@include breakpoint(giant) {
			margin-right: rem(53px);
		}
	}
	&:last-child {
		li {
			&:last-child {
				border-bottom: transparent;
			}
		}
	}

	li {
		border-bottom: 1px solid rgba($dark, 0.2);

		@include breakpoint(small) {
			border-bottom: transparent;
		}
		
		a{
			text-decoration: none;
			color: $dark;
			padding: rem(10px) rem($baseGap);
			display: block;

			@include breakpoint(small) {
				display: inline-block;
				padding: 0;
			}
			
			&:hover,
			&:focus,
			&:active,
			&.active{
				color: $primary !important;
			}
			&.active{
				font-weight: 600;
			}
		}
	}
}