// Use this for default Icomoon

@include fontFaceV2(
	$fontName: "icomoon",
	$fileName: "icomoon",
	$types: (woff, ttf, svg, eot),
	$style: normal,
);

// Example font-face for Roboto
@include fontFaceV2(
    $fontName: "Jost", 
    $fileName: "jost-v3-latin", 
    $weights: ("400", "600", "700"), 
    $types: (woff, woff2)
);