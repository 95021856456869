
// HEADER
//////////////////////////////

#header {
	position: relative;
	padding-top: rem(50px);
	width: 100%;
	
	background-size: cover;

	> .row {
		height: 100%;
	}
	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		
		filter: grayscale(100%);
	}

	body.index & {	
		background: image-url('layout/large-bgHeader.jpg') no-repeat center center/cover;	
		// filter: grayscale(100%);
		
		@include breakpoint(large) {
			height: rem(800px);	
			background: image-url('layout/bgHeader.jpg') no-repeat center center/cover;	
		}		
		@include breakpoint(huge) {
			height: rem(1100px);		
		}
		
	}

	body:not(.index) & {
		background: image-url('layout/large-bgHeader.jpg') no-repeat center 100px;
		background-size: cover;
		margin-bottom: rem(60px);
		padding: rem(50px) 0 rem(146px) 0;
		
		@include breakpoint(large){
			background: image-url('layout/bgHeaderSub.jpg') no-repeat center center/cover;
			margin-bottom: rem(135px);
			padding: rem(146px) 0 0 0;
			height: rem(250px);
			z-index: 1;		
		}		
	}	
	
	.branding-box {
		background-color: $light;
		padding: rem($baseGap);

		.branding {
			display: block;
			max-width: rem(240px);
			width: 100%;
			height: auto;
			margin: 0 auto;
			position: relative;
			z-index: 1;			
		}

		@include breakpoint(large) {
			display: none;
		}
	}

	.data-box-col {
		position: relative;

		@include breakpoint(large, max) {
			display: none;				
		}

		.data-box {
			position: absolute;
			bottom: rem(-470px);
			left: rem($baseGap);
			height: rem(940px);
			width: 100%;			
			background: $gradientTop;
	        background: linear-gradient(0deg, rgba($gradientBottom,1) 0%, rgba($gradientTop,1) 100%);

	        .items {
	        	height: rem(470px);
		        display: flex;
				align-items: center;
				justify-content: center;

				.naviItems {
					position: relative;
					padding-left: rem(40px);
					
					@include breakpoint(giant){
						padding-left: rem(60px);
					}

					&:before {
						content: 'Leistungen';
						display: block;
						color: $light;
						text-transform: uppercase;
						font-weight: 600;
						font-size: rem(14px);
						letter-spacing: ls(350);
						transform: rotate(-90deg);
						transform-origin: 0 0;
						position: absolute;
						left: 0;
						bottom: rem(-20px);
					}
					li {
						margin-bottom: rem(20px);

						@include breakpoint(huge){
							margin-bottom: rem(27px);							
						}

						&:last-child {
							margin-bottom: 0
						}

						a {
							font-weight: 600;
							font-size: rem(22px);
							color: $light;
							text-decoration: none;
							position: relative;

							@include breakpoint(giant){
								font-size: rem(24px);
							}
							@include breakpoint(huge) {
								font-size: rem(30px);
							}

							&:after{
								content: '';
								position: absolute;
								background: $light;
								width: 100%;
								left: 0;
								visibility: hidden;
								opacity: 0;
								height: 1px;
								bottom: rem(-10px);
								.javascript &{
									transition: .5s ease;
								}
						}

							&:hover,
							&:focus,
							&.active {
								&:after{
									opacity: 1;
									visibility: visible;
									bottom: rem(3px);
								}
							}
						}
					}
				}
		    }

		    .worktime {
	        	height: rem(470px);
		        display: flex;
				align-items: center;
				justify-content: center;
				color: $lightGrey;
				font-size: rem(18px);
				line-height: rem(31px);
				text-align: center;

				span {
					display: block;
					color: $light;
					font-weight: 600;
					font-size: rem(22px);
					margin-bottom: rem(2px);

					@include breakpoint(huge) {
						font-size: rem(25px);
					}
				}

				.separator {
					width: rem(197px);
					height: 1px;
					background-color: rgba($light, 0.3);
					margin: rem(43px) auto;
				}
			}
		    
		}
	}

	.claim-box {
		color: $light;
		padding: rem(90px) 0;
		
		@include breakpoint(large) {
			padding: rem(195px) 0 0 0;
		}
		@include breakpoint(huge) {
			padding: rem(492px) 0 0 0;
		}

		span {
			display: block;
			color: white;
			font-size: rem(18px);
			line-height: rem(30px);
			margin-bottom: rem(50px);
			text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.5);
			&:not(.headline){
				font-weight: 550;
			}
			&.headline {
				display: block;
				font-weight: bold;
				font-size: rem(23px);
				line-height: 1.3em;
				color: $light;				
				margin-bottom: rem(20px);

				@include breakpoint(tiny) {
					font-size: rem(28px);
				}

				@include breakpoint(medium) {
					font-size: rem(34px);
				}
				@include breakpoint(large) {
					font-size: rem(36px);					
					margin-bottom: rem(36px);
				}
				@include breakpoint(huge) {
					font-size: rem(40px);
				}
				@include breakpoint(full) {
					font-size: rem(50px);
				}
			}
		}
	}
}

// MAIN CONTENT
//////////////////////////////

main {
	display: block;

	p,
	ol,
	ul,
	dl,
	table {
		@extend .marginBottom;
	}

	ul:not(.unstyledList),
	dl:not(.unstyledList),
	ol:not(.unstyledList) {
		@extend .styledList;
	}

	img,
	.cms-img {
		margin-bottom:rem($baseGap*2);
	}

	.slider-col {
		@include breakpoint(large, max) {
			padding: 0;
		}
	}

	.carousel-box {
		height: rem(300px);

		@include breakpoint(small){
			height: rem(470px);
		}

		.swiper-container {
	    	width: 100%;
	    	height: 100%;
	    }

	    .swiper-slide {
	    	a {
		    	display: block;
		    	height: 100%;
		    	color: $lightGrey;
		    	text-decoration: none;
		    	font-size: rem(16px);
		    	line-height: rem(28px);
		    	padding: 0 rem($baseGap);
		    	display: flex;
		    	align-items: flex-end;

		    	@include breakpoint(huge){
			    	padding: 0 rem(50px);
			    }
			    &:hover {
			    	.desc {
		    			transform:translateY(-10px);
		    		}
		    	}
				
			    
				
				span {
					display: block;
					font-size: rem(22px);
					font-weight: 600;
					color: $light;
					margin-bottom: rem(12px);

					@include breakpoint(huge){
						font-size: rem(25px);
					}
				}

				.desc {
					margin-bottom: rem(50px);
					transition:.35s all;
				}

		    } 
	    }

	    .swiper-lazy-loaded {
			.energiecontrolling {
				background: image-url('index/slider/energiecontrolling.jpg') no-repeat center center/cover;
		    }
		    .spitzenlastmanagement {
				background: image-url('index/slider/spitzenlastmanagement.jpg') no-repeat center center/cover;
			   }
			.netzqualitaet {
				background: image-url('index/slider/netzqualitaet.jpg') no-repeat center center/cover;
			}
			.messtechnik {
				background: image-url('index/slider/messtechnik.jpg') no-repeat center center/cover;
			}
		}

	    .swiper-nav {
		    width: rem(76px);
		    height: rem(34px);
		    position: absolute;
		    top: rem(30px);
		    right: rem(48px);
		    display: flex;
		    justify-content: space-between;
		    z-index: 1;

		    .swiper-button-next,
		    .swiper-button-prev {
			    width: rem(34px);
			    height: rem(34px);
			    background-color: $light;
			    background-image: none !important;
			    border-radius: 100%;
			    top: 0;
			    margin-top: 0;
			    color: $secondary;
			    font-size: rem(20px);
			    display: flex;
				align-items: center;
				justify-content: center;

				transition: all 0.3s ease;

				&:hover,
				&:focus {
					background-color: $primary;
					color: $light;
				}
			}
			.swiper-button-next {
				right: 0;
			}
		    .swiper-button-prev {
		    	left: 0;
			}
		}
	}
	.section {
		margin-bottom: rem(60px);
		@include breakpoint(large){
			margin-bottom: rem(135px);
		}
		#ewpm_navi_files {
			background-color: $medium;
			display:flex;
			flex-wrap:wrap;
			justify-content:center;
			padding: rem($baseGap);
			li {
				margin-right: rem($baseGap);
				width: 100%;
				@include breakpoint(tiny) {
					width: auto;
				}
				&:last-of-type {
					margin-right: 0;
				}
			}
		}

		.contentInfo{
			padding: rem(60px) 0 rem(32px) 0;

			@include breakpoint(large){
				padding: rem(32px) 0;					
			}
			@include breakpoint(giant){
				max-width: rem(405px);
			}
		}
		.bgBox{
			background: $medium;
			padding: rem(60px) rem($baseGap);

			@include breakpoint(large){
				padding: rem(135px) rem(60px);
			}
			@include breakpoint(huge){
				padding: rem(135px) rem(99px);
			}
			strong {
				color: $secondary;
			}
			p{
				&:last-child{
					margin-bottom: 0;
				}
			}
		}	

		.teamBg {
			background-color: $medium;
			padding: rem(20px);
			height: 100%;
			@include breakpoint(giant) {
				padding: rem(40px);
			}
		}	
		.mBottomTeam {
			.ueberUns & {
				margin-bottom: rem($baseLineHeight);
			}
		}

		.partnerWidth {
			height: rem(50px);
			margin: 0;
			@include breakpoint(medium) {
				height: rem(70px);
			}
		}
		.mTop {
			margin-top: rem($baseGap);
		}
		.heightFlex {
			height: 100%;
			display:flex;
			align-items:center;
			border:1px solid $medium;
			padding: rem(30px) rem($baseGap);
			justify-content:center;
			a {
				margin-bottom: 0;
			}
		}

		.impress {			
			@include responsiveImage('index/impress.jpg', (tiny, large), true);

			@include breakpoint(large) {
				height: rem(500px);
			}

			&.lazyLoaded {
				background-size: cover; 
			}

			>.row {
				height: 100%;
			}

			.impress-box {
				height: 100%;				
				padding: rem(90px) rem($baseGap);

				@include breakpoint(large) {
					display: flex;
					align-items: center;
					padding: 0;
				}

				span {
					color: $light;
					font-weight: bold;
					font-size: rem(24px);
					line-height: 1.3em;	

					@include breakpoint(large, max) {
						text-align: center;
						display: block;
					}

					@include breakpoint(large) {
						font-size: rem(30px);
					}
					@include breakpoint(giant) {
						font-size: rem(36px);
					}
					@include breakpoint(huge) {
						font-size: rem(40px);					
					}
					@include breakpoint(full) {
						font-size: rem(50px);					
					}
				}
			}

		}

		.text-box {
			padding-top: rem(60px);

			@include breakpoint(large){ 
				padding-top: rem(110px);
			}

			p{
				&:last-child{
					margin-bottom: 0;
				}
			}
		}

		.contact-info-box {
			padding: rem(100px) rem($baseGap);
			background: $gradientTop;
	        background: linear-gradient(0deg, rgba($gradientBottom,1) 0%, rgba($gradientTop,1) 100%);
	        color: $light;
	        font-weight: 600;
	        font-size: rem(22px);	        
		    display: flex;
		    justify-content: center;	  
		    margin-top: rem(-306px); 
		    margin-bottom: rem($baseLineHeight);

		    @include breakpoint(large, max){ 
		    	display: none;
			}

		    @include breakpoint(giant){
			    font-size: rem(25px);
			} 
			@include breakpoint(huge){
			    font-size: rem(30px);
			}    

	        .contact-data {
	        	display: flex;
	        	justify-content: center;
			    position: relative;
			    margin-bottom: rem(220px);
			    
			    @include breakpoint(huge){
					margin-bottom: rem(185px);
				}

				ul {
					position: relative;		
					padding-left: rem(40px);

					@include breakpoint(giant){	
						padding-left: rem(50px);
					}

					&:before {
						content: 'Kontakt';
						display: block;
						color: $light;
						text-transform: uppercase;
						font-weight: 600;
						font-size: rem(14px);
						letter-spacing: ls(350);
						transform: rotate(-90deg);
						transform-origin: 0 0;
						position: absolute;
						left: 0;
						bottom: rem(-25px);
					}
					li {
						margin-bottom: rem(20px);

						@include breakpoint(huge){
							margin-bottom: rem(31px);							
						}

						&:last-child {
							margin-bottom: 0
						}

						a {
							font-weight: 600;
							color: $light;
							text-decoration: none;
							position: relative;

							&:after{
								content: '';
								position: absolute;
								background: $light;
								width: 100%;
								left: 0;
								visibility: hidden;
								opacity: 0;
								height: 1px;
								bottom: rem(-10px);
								.javascript &{
									transition: .5s ease;
								}
						}

							&:hover,
							&:focus,
							&.active {
								&:after{
									opacity: 1;
									visibility: visible;
									bottom: rem(3px);
								}
							}
						}
					}
				}
		    }

		    .news-box {
			    text-align: center;
			    padding: 0;
			    font-size: rem(20px);

			    @include breakpoint(giant){
				    padding: 0 rem(18px);
				    font-size: rem(22px);
				}
			    @include breakpoint(huge){
				    padding: 0 rem(40px);
				}

			    span {
			    	display: block;
				    font-weight: 600;
				    font-size: rem(18px);
				    line-height: rem(30px);
				    margin-bottom: rem(30px);

				    @include breakpoint(giant){
					    font-size: rem(20px);
					    line-height: rem(35px);
					}
				}	

				.btn {
					
				}			
			}
		}
		
	}
}

#privacyPolicy
{
	a
	{
		display: inline-block; 
		word-break: break-all;
	}
}

// FOOTER
//////////////////////////////

#footer {
	.Maps{
		height: rem(350px);

		.cmsBackend & {
			display: none;
		}

		@include breakpoint(large){
			padding-bottom: 0;
			height: rem(587px);
		}

		iframe {
			filter: grayscale(100%);
			height: rem(350px);
			transition:.35s all;
			&:hover {
				filter: grayscale(0%);	
			}

			@include breakpoint(large){
				height: rem(587px);
			}
		}
	}
	.footerData{
		padding: rem(60px) 0;
		display: flex;
		flex-direction: column;
		text-align: center;

		@include breakpoint(small){
			text-align: left;
		}

		@include breakpoint(large){
			padding: rem(135px) 0;
		}
		@include breakpoint(large){
			flex-direction: row;
			justify-content: space-between;
		}

		.col {
			margin-bottom: rem($baseLineHeight);

			@include breakpoint(small, max){
				&:nth-of-type(3) {order: 4;}
				&:nth-of-type(4) {order: 3;}
			}

			@include breakpoint(large){
				margin-bottom: 0;
			}
		}

		.boxTitle{
			display: inline-block;
			font-weight: 600;
			font-size: rem(20px);
			color: $secondary;
			margin-bottom: rem(10px);
		}

		a {
			color: $dark;

			&:hover,
			&:focus,
			&:active {
				color: $secondary;
			}
		}


	}
}
.yeet {
	@include breakpoint(default){
		display: none;
	}
	@include breakpoint(giant){
		display: block;
	}
}
.notyeet {
	@include breakpoint(default){
		display: block;
	}
	@include breakpoint(giant){
		display: none;
	}
}