.marginBottom {
  margin-bottom: rem($baseLineHeight);
}
.marginBottomHalf {
  margin: 0 rem($baseLineHeight/2) rem($baseLineHeight/2) 0; 
}


.highlight {
  color: $secondary;
}

.preload * {
  transition: none !important;
}

.btn {
  @extend %animatedTransform;
  background: $secondary;
  border: none;
  color: $light;
  cursor: pointer;
  display: inline-block;
  font-size: rem(18px);
  line-height: rem($baseLineHeight);
  padding: 0 rem(40px) 0 rem(78px);
  text-align: center;
  text-decoration: none;
  font-family: $mainFont;
  border-radius: rem(30px);
  font-weight: 600;
  position: relative;
  //height: rem(59px);
  line-height: rem(59px);
  //overflow: hidden;

  &:hover,
  &:active,
  &:focus {
    background-color: rgba($secondary, 0.2);
    color: $secondary;
  }  

  *[class^="icon"] {
    vertical-align: middle;
    font-size: rem(20px);
    font-weight: 400;
    background-color: $primary;
    color: $light;
    position: absolute;
    left: -1px;
    top: -1px;
    width: rem(61px);
    height: rem(61px);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.claim-box,
.info, 
.news-box {
  .btn {
    background: $light;
    color: $secondary;

    &:hover,
    &:active,
    &:focus {
      background: $secondary;
      color: $light;
    } 
  }
} 

.pdf-icon {
  position: relative;

  &:before {
    font-family: $iconFont;
    @extend .icon;
    @extend .icon-arrow-right;
    font-size: rem(20px);
    font-weight: 400;
    background-color: $primary;
    color: $light;
    position: absolute;
    left: -1px;
    top: -1px;
    width: rem(61px);
    height: rem(61px);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
} 

.clear {
  clear: both;
  float: none;
}

.clearfix {
  @include pie-clearfix;
}

.hidden {
  display: none !important;
}

.hideText {
  @include hideText();
  display: inline-block;
  overflow: hidden;
  width: 0;
}

.floatLeft {
  float: left;
}

img.floatLeft {
  margin-right: 1rem;
}

.floatRight {
  float: right;
}

img.floatRight {
  margin-left: 1rem;
}

.fluid {
  height: auto;
  width: 100%;
}

.nonFluid {
  width: auto !important;
}

.alignLeft {
  text-align: left;
}

.alignRight {
  text-align: right;
}

.alignCenter {
  text-align: center;
}

.wide {
  width: 100%;
  display: block;
}

/* 16:9 video resolutions */
.videoContainer {
  &:not(.videoTag) {
    position: relative;
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;

    iframe {
      border: none;
      height: 100%;
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
    }
  }

  video {
    width: 100%;
    height: auto;
  }
}
.googleMaps {
  @extend .videoContainer;

  @include breakpoint(medium) {
    height: rem(400px);
  }
}

body {
  &:before {
    $content: "default:" + $bpContext;
    width: str_length($content) * 6pt + $baseGap * 2;

    @each $point, $width in $gridMap {
      $content: $content + "...." + $point + ":" + nth(map-get(map-get($gridMap, $point), width), 1);

      @include breakpoint($point) {
        width: str_length($content) * 6pt + $baseGap * 2;
      }
    }

    content: "#{$content}";
    display: none !important; /* Prevent from displaying. */
  }

  &.devmode:before,
  &.debug:before {
    background: $alert;
    border-color: $alert;
    border-style: solid;
    border-width: 0 rem($baseGap);
    color: $light;
    display: block !important;
    font-family: Courier;
    font-size: 10pt;
    left: 0;
    line-height: 2.5;
    overflow: hidden;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    white-space: nowrap;
    z-index: 99999;
  }
  &:hover:before {
    opacity: 0.5;
    pointer-events: none;
  }
}

// Animations
%animatedTransform {
  transition: 0.3s;
}

.notificationArea {
  position: fixed;
  bottom: rem(40px);
  left: rem(40px);
  z-index: 999999;
  visibility: hidden;
  display: block;
  
  @include breakpoint(large) {
    left: rem(60px);
  }

  > :not(:first-child):not(.hide).transitionIn {
    margin-top: $baseGap;
  }

  .notification {
    display: block;
    position: relative;
    transition: 300ms all ease-in-out;
    overflow: hidden;
    padding:0;
    background: $alert;
    font-size: rem(12px);
    line-height: rem(16px);
	  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.35);
    width: rem(300px);
    max-width: calc(100vw - 80px);
    transform-origin: left center;
    visibility: hidden;
    opacity: 0;
    max-height:none;
    height: 0;
    box-sizing:border-box;
    margin-left:-100px;

    .noteContent {
      padding:1rem;
      display: block;
    }

    @include breakpoint(large) {
      font-size:rem(16px);
      line-height: rem(22px);
    }

    &.transitionIn {
      visibility: visible;
      opacity: 1;
      max-height:9999999px;
      height: auto;
      margin-left:0;
    }

    &,
    a {
      color: $light;
    }

    &.success {
      background: #333;
      
      &, .close {
        color: #fff;
      }
    }
    &.error {
      background: $alert
    }
    &.success, &.error {
      p {
        margin-bottom: rem(5px);
      }
      p + ul {
        margin-top: rem(5px);
      }
    }

    .close {
      position: absolute;
      top: 0;
      right: 0;
      padding-top: 5px;
      padding-right: 8px;
      cursor: pointer;
      color: #fff;
      font-size: 1.2rem;
    }

    &.hide {
      margin-left:-100px;
      opacity: 0;
      visibility: hidden;
      height:0;
    }
  }
}

html.inProgress {
  pointer-events: none;
  &:before {
    content:"";
    width: 100vw;
    height: 100vh;
    background:rgba(0,0,0,0.5);
    position: fixed;
    top:0;
    left:0;
    z-index:99999998
  }
  &:after {
    content:"";
    position: fixed;
    top: 0;
    height: 0;
    background: url('/images/layout/loading.svg') no-repeat center center;
    height: 100vh;
    width: 100vw;
    z-index:99999999;

    filter:invert(100%);
  }
}

.marginBottom{
  margin-bottom: 2* $baseGap !important;
}

.flexthoseforclient{
  display: flex;
  @include breakpoint(default){
    flex-direction: column;
  }
  @include breakpoint(giant){
    flex-direction: row;
  }
}

.sameHeight{
  height: 300px;
  width: auto;
  object-fit: cover;
}

.fillthis {
  object-fit: fill;
  margin-right: $baseGap;
}

.marginleft{
  margin-left: $baseGap;
}